import { Fragment, useState, useCallback } from "react";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import MobilekeyMenual from "./MobilekeyMenual";
import CheckOut from "./CheckOut";
import * as GoogleAnalytics from "../../lib/google-analytics";
import QRCode from "qrcode.react";

interface ServiceType {
  svcCode: string;
  svcName: string;
  qty: number;
  amt: number;
  totalAmt: number;
}

const BookingList = ({ bookingItem, qrCode, checkOut }: any) => {
  const [isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual] = useState(false);
  const [isOpenModalCheckOut, setIsOpenModalCheckOut] = useState(false);

  const isDANKE = bookingItem.serviceList.some((item: ServiceType) => item.svcCode === "DANKE");
  const cntAdultBreakfast = bookingItem.serviceList.filter((item: ServiceType) => item.svcCode === "ADBF" || item.svcCode === "BF1").length;
  const cntKidBreakfast = bookingItem.serviceList.filter((item: ServiceType) => item.svcCode === "KDBF").length;

  const openModalMobilekeyMenual = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_mobilekey_menual",
    });
    setIsOpenModalMobilekeyMenual(true);
  }, []);

  const closeModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(false);
  }, []);

  const openModalCheckout = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_checkout_request",
    });
    setIsOpenModalCheckOut(true);
  }, []);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const dayFormatter = (day: string) => {
    return (
      {
        Sun: "일",
        Mon: "월",
        Tue: "화",
        Wed: "수",
        Thu: "목",
        Fri: "금",
        Sat: "토",
      }[day] || day
    );
  };

  const setBookingPeriod = (date: string | Date) => {
    return `${moment(date).format("YYYY.MM.DD")} (${dayFormatter(moment(date).format("ddd"))})`;
  };

  return (
    <Fragment>
      <div className="checked-in-background-container">
        <div className="opacity-background">
          <span className="welcome-message">
            {bookingItem.guestName} 고객님,
            <br />
            환영합니다.
          </span>
          <div className="booking-id-container">
            <span className="booking-id-label">예약번호</span>
            <span className="booking-id-value">{bookingItem.rsvnNo}</span>
          </div>
        </div>
      </div>
      <div className="checked-in-booking-card-container">
        <span className="hotel-name">
          {bookingItem.propertyName} {bookingItem.zoneTypeName}
        </span>
        <span className="room-name">{bookingItem.roomNo}</span>
        <div className="horizontal-line mt-15 mb-20"></div>
        <div className="userkey-container">
          <div className="userkey-item mb-15">
            <span className="userkey-item-label">객실 비밀번호</span>
            <span className="userkey-item-value">
              {bookingItem.pincode ? bookingItem.pincode : "-"}
              <FaCheck />
            </span>
          </div>
          <div className="userkey-item">
            <span className="userkey-item-label">
              모바일키
              <img className="tip-icon ml-2 mb-4" src="../../assets/images/question-mark.png" alt="" onClick={openModalMobilekeyMenual} />
            </span>
            <span className="userkey-item-value">{bookingItem.exchangekey ? bookingItem.exchangekey : "-"}</span>
          </div>
        </div>

        <div className="qrCode-container">
          {qrCode ? (
            <>
              <span>공용 도어 QR</span>
              <QRCode value={qrCode} size={180} />
            </>
          ) : (
            <>
              <span>공용 도어 QR 코드가 없습니다.</span>
              <button onClick={() => window.location.reload()}>새로고침</button>
            </>
          )}
        </div>

        <div className="checked-in-booking-period-container">
          <img className="period-icon mt-2" src="../../assets/images/period.png" alt="" />
          <div className="period-item-container">
            <div className="period-item mb-8">
              <span className="period-item-label">체크인</span>
              <span className="period-item-value">{bookingItem.arrvDate ? `${setBookingPeriod(bookingItem.arrvDate)} 15:00` : "-"}</span>
            </div>
            <div className="period-item">
              <span className="period-item-label">체크아웃</span>
              <span className="period-item-value">{bookingItem.deptDate ? `${setBookingPeriod(bookingItem.deptDate)} 11:00` : "-"}</span>
            </div>
          </div>
        </div>

        <button className="mobile-check-out-button" onClick={openModalCheckout}>
          모바일 체크아웃 하기
        </button>

        <ul className="service-template">
          {cntAdultBreakfast + cntKidBreakfast > 0 && (
            <>
              <li className="service-title">조식권</li>
              <li>이용시간: 07:00~10:00 (09:30 입장마감)</li>
              <li>이용장소: 3층 플로라 레스토랑</li>
              <li>
                성인 {cntAdultBreakfast}명, 소인 {cntKidBreakfast}명
              </li>
              <br />
            </>
          )}
          {isDANKE && (
            <>
              <li>카페 2만원권 : 본영수증 지참하여 당께에서 이용 가능합니다</li>
              <br />
            </>
          )}
          <li className="service-title">팀준피트니스 헬스장 일일권 이용쿠폰</li>
          <li>쿠폰 유효기간: 당일에 한함(프론트에서 도장찍어야함)</li>
          <li>이용시간: 주중 06:00~24:00 / 주말, 공휴일 10:00~18:00</li>
          <li>운동복, 수건 별도 유상 구매, 별도 구비 필요</li>
          <li>본 쿠폰은 1회용으로 당일만 사용가능합니다.</li>
          <li>실내 운동화 및 양말 지참 필수</li>
          <li>반드시 신발을 벗고 입장해 주시기 바랍니다.</li>
        </ul>
      </div>
      <MobilekeyMenual isOpen={isOpenModalMobilekeyMenual} onClose={closeModalMobilekeyMenual} />
      <CheckOut isOpen={isOpenModalCheckOut} onClose={closeModalCheckOut} checkOut={checkOut} />
    </Fragment>
  );
};

export default BookingList;
